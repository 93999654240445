import React from 'react'

import { StaticQuery, graphql } from 'gatsby'
import { FaCocktail, FaCalendarAlt, FaStar } from 'react-icons/fa'

import Lead from '@components/Lead'
import Link from '@components/Link'
import Tag from '@components/Tag'
import Title from '@components/Title'
import PaddedBlock from '@components/PaddedBlock'
import Media from '@components/Media'
import SEO from '@components/SEO'

const gqlQuery = graphql`
  query IndexPageQuery {
    site {
      siteMetadata {
        keywords
      }
    }
  }
`

const IndexPage = () => (
  <StaticQuery
    query={gqlQuery}
    render={data => (
      <React.Fragment>
        <SEO title="Welcome" keywords={data.site.siteMetadata.keywords} />

        <PaddedBlock>
          <Lead>
            Welcome onboard of <abbr title="Motor Yatch">M/Y</abbr> Christina
          </Lead>
          <article>
            <p>
              We proudly present our <strong>Yacht Christina</strong>.
            </p>
            <p>
              We can provide a relaxing hour or more, <strong>cruising privately, on the Nile River</strong>, admiring
              the typical Egyptian shoreline or the bright city lights at night.
            </p>
            <p>
              We have an{' '}
              <strong>
                <Link to="/reach-us/" scrollToMainContent={false}>
                  accessible dock
                </Link>{' '}
                on the shore of the Nile
              </strong>{' '}
              with all the <Link to="/services/">amenities</Link> at hand.
            </p>
            <Media position="right" figure={<FaCalendarAlt />}>
              <Title level={3}>Make your reservation</Title>
              <p>
                <Link to="/gallery/">Our yacht</Link> can accommodate up to <strong>25 persons</strong> comfortably. Our
                working hours are from <Tag>12:00 PM</Tag> to <Tag>12:00 AM</Tag> daily.
              </p>
              <p>
                However we recommend advanced <Link to="/reservation/">reservations</Link> to guarantee your cruise.
              </p>
            </Media>
            <Media position="left" figure={<FaCocktail />}>
              <Title level={3}> Sit back on the deck and enjoy</Title>
              <p>Satisfy your palate with delicious dishes and sip a refreshing drink while cruising the Nile River.</p>
            </Media>
            <Media position="right" figure={<FaStar />}>
              <Title level={3}>They are talking about us</Title>
              <p>
                Our satisfied customers include <strong>tourists</strong> visiting Cairo,{' '}
                <strong>Embassies Personnel</strong>, and expatriate companies members.
              </p>
              <p>
                Make sure to check out their <Link to="/reviews/">reviews</Link>.
              </p>
            </Media>
          </article>
        </PaddedBlock>
      </React.Fragment>
    )}
  />
)

export default IndexPage
